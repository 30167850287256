import axios from 'axios';

export const BASE_URL = 'https://gydfyndapi.buydirectories.com';

// Fetch order data by ID
export const fetchInvoiceDetails = (id) => {
  return axios.post(`${BASE_URL}/Order/GetOrderData_ByOrderID`, { orderID: id });
};

// Fetch PDF data
export const fetchPdfData = (id) => {
  return axios.post(`${BASE_URL}/Order/DownloadCustomerGSTInvoice`, { orderID: id });
};
